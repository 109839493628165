import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';
import { commonHeaderColor } from '../../../util';


const Page = ({ data }) => (
  <>
    <SEO
      title="Obtenez votre bon NeoSurf rapidement et en toute sécurité"
      description="Avec Neosurf, vous pouvez acheter, jouer ou vous abonner à vos services préférés sans complications."
      alternateLanguagePages={[
        {
          path: 'buy-neosurf',
          language: 'en'
        },
        {
          path: 'acheter-neosurf',
          language: 'fr'
        },
        {
          path: 'buy-neosurf',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={commonHeaderColor} />
    <ContentRow backgroundColor={commonHeaderColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://bit.ly/2AlU6Ch">
            <Img fluid={data.myneosurf.childImageSharp.fluid} alt="MyNeoSurf Logo" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="smaller h3 smaller">Achetez Neosurf</h1>
            <p className="textColorDark">Obtenez votre bon NeoSurf rapidement et en toute sécurité!</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <ContentRow padV>
      <p className="textColorDark">Neosurf est l'un des moyens les plus pratiques et sécurisés pour effectuer des paiements en ligne. Vous pouvez utiliser Neosurf pour acheter, jouer ou vous abonner à vos services préférés, le tout sans complications. Il s'agit d'une méthode de paiement simple et basée sur le cash qui protège vos informations personnelles, vous permettant ainsi d'effectuer vos achats en toute tranquillité.</p>
      <p className="textColorDark">Acheter un bon Neosurf est très facile. Vous pouvez vous procurer Neosurf dans des points de vente autorisés, tels que des magasins de proximité locaux, des supermarchés, des kiosques et d'autres commerçants. Les bons sont disponibles avec différentes valeurs, ce qui vous permet de choisir facilement le montant qui correspond à vos besoins. Cette flexibilité fait de Neosurf une excellente option pour ceux qui souhaitent gérer efficacement leurs dépenses. Par exemple, si vous essayez de respecter un budget, vous pouvez acheter Neosurf avec un montant planifié, ce qui réduit le risque de dépassement.</p>
      <p className="textColorDark">La confidentialité est l'un des plus grands avantages de Neosurf, car contrairement aux méthodes de paiement traditionnelles comme les cartes de crédit, il n'est pas nécessaire de partager des informations personnelles ou financières sensibles. C'est le choix idéal pour ceux qui privilégient la sécurité et l'anonymat lors des transactions en ligne.</p>
      <p className="textColorDark">Neosurf est largement accepté sur des milliers de plateformes en ligne à travers le monde, y compris des sites de jeux, des boutiques de commerce électronique, des services d'abonnement et bien plus encore. Les joueurs adorent Neosurf car il permet des recharges rapides et faciles pour les comptes de jeux sans délai, ce qui en fait également une excellente option pour les casinos.</p>
      <p className="textColorDark">Neosurf est particulièrement avantageux pour ceux qui n'ont pas accès aux services bancaires traditionnels ou aux cartes de crédit. Avec Neosurf, vous pouvez payer des services en ligne sans avoir besoin de compte bancaire. Pour ceux qui préfèrent utiliser de l'argent liquide pour leurs transactions en ligne, Neosurf est la solution parfaite.</p>
      <p className="textColorDark">Si vous êtes prêt à acheter un bon Neosurf, commencez par trouver un point de vente de confiance près de chez vous ou un détaillant en ligne vérifié. Après avoir obtenu votre bon, l'utiliser est très simple. Il vous suffit d'entrer le code du bon sur toute plateforme qui accepte Neosurf. Il n'y a pas de frais cachés ni de procédures compliquées, ce qui en fait un moyen de paiement direct et sans tracas.</p>
      <p className="textColorDark">Neosurf est devenu un choix populaire dans le monde entier car il est facile et fiable. Si vous recherchez une méthode sûre et simple pour faire des achats en ligne, un bon Neosurf vous offrira cette expérience. Obtenez votre bon Neosurf dès maintenant!</p>
    </ContentRow>
  </>
)

export default Page;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    myneosurf: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "myneosurf/myneosurf-header.png"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
